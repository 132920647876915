<template>
  <div class="ContactPerson">
    <div class="searchNav">
      <el-button
        class="button"
        icon="el-icon-plus"
        type="primary"
        style="margin-left: 0"
        @click="handleAdd"
        >新增</el-button
      >
      <el-button class="button" type="text" @click="EditEmail"
        >配置邮箱</el-button
      >
      <div class="searchParams">
        <div>
          <radioGroup @radioChange="radioChange" @complete="radioComplete" />
        </div>
        <el-input
          class="input"
          v-model="queryParam.keyword"
          placeholder="请输入承运商名称/编码"
          clearable
          @keydown.enter.native="getDataList"
        ></el-input>
        <el-button class="button" @click="getDataList">查询</el-button>
      </div>
    </div>
    <div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :max-height="height"
        v-loading="loading"
      >
        <!-- <el-table-column prop="CompanyName" label="所属公司" width="200">
        </el-table-column> -->
        <el-table-column prop="CustomerName" label="客户"> </el-table-column>
        <el-table-column prop="CreateTime" label="日期">
          <template slot-scope="scope">
            {{ scope.row.CreateTime?.substring(0, 10) }}
          </template>
        </el-table-column>
        <el-table-column prop="State" label="状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.State == 0">待确认</el-tag>
            <el-tag v-if="scope.row.State == 1" type="success">已确认</el-tag>
            <el-tag v-if="scope.row.State == 2" type="danger">已拒绝</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="170" align="center">
          <template slot-scope="scope">
            <el-button type="text" @click="handleView(scope.row)"
              >查看</el-button
            >
            <el-button
              type="text"
              @click="handleEdit(scope.row)"
              v-if="scope.row.State == 0"
              >编辑</el-button
            >
            <el-button type="text" @click="handleEmail(scope.row)"
              >发送邮箱</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="total,prev, pager, next,sizes"
        :total="pagination.total"
        :current-page="pagination.current"
        :page-size="pagination.pageSize"
        :page-sizes="pagination.pageSizeOptions"
        style="text-align: right; margin-top: 8px; padding: 0"
        @current-change="currentChange"
        @size-change="handleSizeChange"
      >
      </el-pagination>
    </div>
    <el-dialog title="客户邮箱" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="Email" placeholder="请输入客户邮箱"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="dialogLoading" @click="handleClose"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Email ref="Email" />
    <EditForm ref="editForm" :parentObj="{ ...this }" />
  </div>
</template>

<script>
import radioGroup from "@/components/CompanyRadioGroup.vue";
import EditForm from "./ReconciliationForm.vue";
import Email from "./Email.vue";
export default {
  props: {},
  components: {
    EditForm,
    radioGroup,
    Email,
  },
  mounted() {},
  created() {},
  computed: {
    height() {
      return document.documentElement.clientHeight * 0.75;
    },
  },
  data() {
    return {
      dialogVisible: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        pageSizeOptions: [25, 50, 100],
      },
      sorter: {
        field: "Id",
        order: "desc",
      },
      tableData: [],
      fileList: [],
      queryParam: { SupplieId: "全部" },
      loading: false,
      dialogVisible: false,
      Email: "",
      row: {},
      dialogLoading: false,
    };
  },
  methods: {
    // 配置邮箱
    EditEmail() {
      if (this.queryParam.SupplieId.split(",").length > 1)
        return this.$message.error("请选择公司");

      this.$refs.Email.openDialog(this.queryParam.SupplieId);
    },
    // 发送邮箱
    handleEmail(row) {
      console.log(row);
      this.row = row;
      this.dialogVisible = true;
    },
    // 邮箱确认
    handleClose() {
      if (!this.Email) return this.$message.error("请输入邮箱");
      this.dialogLoading = true;
      this.$http
        .post("/Base/Base_Reconciliation/SendMail", {
          Id: this.row.Id,
          CompanyId: this.row.Company,
          RecipientEmail: this.Email,
          CcusName: this.row.CustomerName,
          CodeDiv: this.row.LetterCode
            ? `<div>对账函密码:${this.row.LetterCode}</div>`
            : "<div></div>",
          Url: "https://psq.yoojet.com/#/SendSMSMobileCopy?prop=" + this.row.Id,
        })
        .then((res) => {
          this.dialogLoading = false;
          if (res.Success) {
            this.dialogVisible = false;
            this.$message.success("发送成功");
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
    radioComplete(e) {
      if (e) {
        this.queryParam.SupplieId = e;
        this.getDataList();
      }
    },
    radioChange(e) {
      if (e == "more") return;
      this.queryParam.SupplieId = e;
      this.getDataList();
    },
    handleAdd() {
      if (this.queryParam.SupplieId.split(",").length > 1) {
        return this.$message.error("请选择公司");
      } else {
        this.$http
          .post(
            "/Base/Base_Reconciliation/GetCompanyTemp?CompanyId=" +
              this.queryParam.SupplieId,
            {}
          )
          .then((res) => {
            console.log(res);
            if (res.Success) {
              this.$refs.editForm.openForm(
                "新增对账函",
                res.Data.Content,
                this.queryParam.SupplieId
              );
            } else {
              this.$message.error(res.Msg);
            }
          });
      }
    },
    handleEdit(row) {
      this.$refs.editForm.openForm("编辑对账函", row.Id, row.Company);
    },
    handleView(row) {
      let url = `${location.href.split("#")[0]}#/SendSMSCopy?prop=${row.Id}`;
      window.open(url, "_blank");
    },
    currentChange(page) {
      this.pagination.current = page;
      this.getDataList();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getDataList();
    },
    getDataList() {
      this.loading = true;
      this.$http
        .post("/Base/Base_Reconciliation/GetRectionList", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          SortField: this.sorter.field || "Id",
          SortType: this.sorter.order,
          Search: this.queryParam,
        })
        .then((resJson) => {
          this.loading = false;
          if (resJson.Success) {
            this.tableData = resJson.Data;
            if (this.tableData) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
          }
        });
    },
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.del {
  color: red;
}
</style>
