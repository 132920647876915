<template>
  <el-drawer
    :title="title"
    :visible.sync="drawer"
    :direction="direction"
    :before-close="handleClose"
    destroy-on-close
    custom-class="drawerClass"
    size="60%"
  >
    <el-form
      class="form"
      ref="form"
      label-width="90px"
      style="box-sizing: border-box; overflow: hidden; padding-right: 20px"
    >
      <el-row :gutter="20">
        <el-col
          :span="12"
          v-for="(item, index) in printlist"
          :key="index + item.Keys"
        >
          <el-form-item
            :label="item.Key"
            v-if="item.type == '1' && item.Keys !== 'Time'"
          >
            <CustomerSearchSelect
              v-if="item.Keys == 'CustomerName'"
              @select="CustomerSelect($event, item)"
              :CompanyId="CompanyId"
              v-model="item.value"
            />
            <el-date-picker
              v-else-if="item.Keys == 'Range'"
              v-model="item.value"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              @change="rangeChange"
            >
            </el-date-picker>
            <el-input v-else v-model="item.value"></el-input>
          </el-form-item>
        </el-col>
        <el-col v-for="(item, index) in printlist" :key="index" :span="24">
          <el-form-item v-if="item.Keys == 'text'" label="">
            <span v-for="(im, ix) in item.value.split('{}')" :key="ix">
              {{ im }}
              <el-input
                class="dInput"
                v-if="ix !== item.value.split('{}').length - 1"
                v-model="dynamic['P' + index + 'F' + ix]"
              ></el-input>
            </span>
          </el-form-item>
        </el-col>
      </el-row>
      <div
        style="padding: 0 20px"
        v-for="(item, index) in printlist"
        :key="index"
        v-loading="tableLoading"
      >
        <el-table
          v-if="item.type == '4'"
          :data="item.datalist"
          max-height="600"
        >
          <el-table-column
            v-for="(im, ix) in item.columns"
            :key="im.dataIndex"
            :prop="im.dataIndex"
            :label="im.title"
          >
            <template slot-scope="scope">
              <div v-if="im.dataIndex == 'CreateTime'">
                {{ scope.row[im.dataIndex]?.substring(0, 10) }}
              </div>
              <div v-else>
                {{
                  scope.row[im.dataIndex]
                    ? toThousands(scope.row[im.dataIndex])
                    : ""
                }}
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-form>
    <div style="height: 50px"></div>
    <div
      :style="{
        position: 'absolute',
        right: 0,
        bottom: 0,
        width: '100%',
        borderTop: '1px solid #e9e9e9',
        padding: '5px 16px',
        background: '#fff',
        textAlign: 'right',
        zIndex: 99999,
      }"
    >
      <el-button @click="drawer = false">取消</el-button>
      <el-button type="primary" :loading="btnLoading" @click="handleSubmit"
        >保存</el-button
      >
    </div>
  </el-drawer>
</template>

<script>
import { toThousands } from "@/utils/Tools.js";
import CustomerSearchSelect from "@/components/CustomerSearchSelect.vue";
import CompanySearchSelect from "@/components/CompanySearchSelect.vue";
export default {
  components: {
    CompanySearchSelect,
    CustomerSearchSelect,
  },
  props: {
    parentObj: { type: Object },
  },
  data() {
    return {
      drawer: false,
      direction: "rtl",
      entity: {},
      CustomerSign: "",
      btnLoading: false,
      title: "",
      printlist: [],
      dynamic: {},
      CompanyId: "",
      newEntity: {},
      StartTime: "",
      EndTime: "",
      tableLoading: false,
      toThousands: toThousands,
      tableIndex: -1,
    };
  },
  methods: {
    blTable() {
      if (this.tableIndex !== -1) {
        let num = 0;
        this.printlist.forEach((item) => {
          if (
            (item.Keys == "CustomerName" && item.value) ||
            (item.Keys == "Range" && item.value)
          ) {
            num += 1;
          }
        });
        if (num >= 2) return true;
        else return false;
      } else return true;
    },
    rangeChange(e) {
      this.StartTime = e[0];
      this.EndTime = e[1];
      if (this.blTable()) {
        this.getTableData();
      }
    },
    // 公司选择
    CompanySelect(row) {
      console.log(row);
    },
    CustomerSelect(row, item) {
      item.value = row.cCusName;
      this.newEntity.CustomerId = row.Id;
      if (this.blTable()) {
        this.getTableData();
      }
    },
    init() {
      this.entity = {};
      this.newEntity = {};
      this.printlist = [];
      this.dynamic = {};
      this.drawer = true;
    },
    openForm(title, content, CompanyId) {
      this.init();
      this.CompanyId = CompanyId;
      this.title = title;
      if (this.title == "新增对账函") {
        this.entity = content;
        this.printlist = JSON.parse(content).printlist;
        this.printlist = this.printlist.sort((a, b) => a.sort - b.sort);
        this.tableIndex = this.printlist.findIndex((item) => item.type == "4");
        if (this.tableIndex !== -1)
          this.printlist[this.tableIndex].datalist = [];
      } else {
        this.getDetailList(content);
      }
    },
    getTableData() {
      this.tableLoading = true;
      this.$http
        .post("/BO/BIllcollection/GetReconciliationData", {
          CompanyId: this.CompanyId,
          CustomerId: this.newEntity.CustomerId,
          EndTime: this.EndTime,
          StartTime: this.StartTime,
        })
        .then((res) => {
          this.tableLoading = false;
          this.printlist[this.tableIndex].datalist =
            res.Data.BIllcollectionList;
        });
    },
    getDetailList(id) {
      this.$http
        .post("/Base/Base_Reconciliation/GetRecDetail?Id=" + id)
        .then((res) => {
          console.log(res);
          if (res.Success) {
            this.newEntity = res.Data;
            this.entity = res.Data.Content;
            this.printlist = JSON.parse(res.Data.Content).printlist;
            this.printlist = this.printlist.sort((a, b) => a.sort - b.sort);
            this.tableIndex = this.printlist.findIndex(
              (item) => item.type == "4"
            );
            // 表格赋值
            if (this.tableIndex !== -1) {
              this.printlist[this.tableIndex] = JSON.parse(
                res.Data.SpanContent
              ).printlist[this.tableIndex];
            }
            JSON.parse(res.Data.SpanContent).printlist.forEach((item) => {
              if (item.Keys != "text" && item.type != "4") {
                let idx = this.printlist.findIndex(
                  (im) => item.Keys == im.Keys
                );
                if (idx !== -1) {
                  this.$set(this.printlist[idx], "value", item.value);
                }
              }
            });
            this.dynamic = JSON.parse(res.Data.SpanValue);
          }
        });
    },
    handleClose(done) {
      done();
    },
    handleSubmit() {
      console.log(this.dynamic);
      // input 拼接赋值
      this.printlist.forEach((item, index) => {
        if (item.Keys == "text") {
          let str = "";
          item.value.split("{}").forEach((im, ix) => {
            if (ix !== item.value.split("{}").length - 1) {
              str +=
                im +
                '<span class="a">' +
                (this.dynamic["P" + index + "F" + ix] || "") +
                "</span>";
            } else {
              str += im;
            }
          });
          item.value = str;
        }
      });
      // 邮箱html模板
      let html = JSON.parse(JSON.stringify(this.printlist))
        .filter((item) => item.type != 3)
        .sort((a, b) => a.sort - b.sort);
      html.forEach((item) => {
        if (item.Keys == "Title") item.value = `<h1>${item.value}</h1>`;
        else if (item.Keys == "text")
          item.value = `<p style="text-indent: 2em;">${item.value}</p>`;
        else if (item.Keys == "CustomerName")
          item.value = `<p>收货方: ${item.value}</p>`;
      });
      let newHtml = html.map((item) => item.value).join("");
      // 赋值
      this.btnLoading = true;
      this.newEntity.Company = this.CompanyId; // 公司
      this.newEntity.content = this.entity; //初始模板
      let SpanContent = JSON.parse(this.entity);
      SpanContent.printlist = this.printlist;
      console.log(SpanContent.printlist);
      this.newEntity.HtmlContent = `<div>${newHtml}</div>`;
      this.newEntity.SpanContent = JSON.stringify(SpanContent); // 拼接数据
      this.newEntity.SpanValue = JSON.stringify(this.dynamic); // 段落填写数据
      this.$http
        .post("/Base/Base_Reconciliation/SaveData", this.newEntity)
        .then((res) => {
          this.btnLoading = false;
          if (res.Success) {
            this.$message.success("操作成功");
            this.parentObj.getDataList();
            this.drawer = false;
          } else {
            this.$message.error(res.Msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.dInput {
  width: 200px;
}
</style>